import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    ColumnsFromApi,
    FilingPeriod,
    FilingPeriodFpList,
    FilingPermit,
    FilingPermitList,
    FilingPermitStatusEnum,
    MappedColumns,
    PermitList,
    YearAndQuarter,
} from 'src/app/modules/filing/models/filing.model';
import {
    ApiResponse,
    ApiResponseAny,
    PagingData,
} from 'src/app/core/models/api-response.model';
import { environment } from 'src/environments/environment';

import { PermitTypeEnum } from '../permit-setup/models/permit.model';
import { DateService } from 'src/app/shared/services/date.service';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { FileService } from 'src/app/shared/services/file.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { GetDataOptions } from 'src/app/shared/model/shared.model';
import { DownloadReportOptions } from 'src/app/shared/model/download-report-dialog.model';

const API_URL = `${environment.apiUrl}/cmp/`;

@Injectable({
    providedIn: 'root',
})
export class FilingService {
    constructor(
        private http: HttpClient,
        private dateService: DateService,
        private fileService: FileService,
        private spinner: NgxSpinnerService
    ) {}

    fuelAndMileageImported$: BehaviorSubject<{
        isFuelImported: any;
        isMileageImported: any;
    }> = new BehaviorSubject({
        isFuelImported: false,
        isMileageImported: false,
    });

    fuelAndMileageImportedOverview$: BehaviorSubject<{
        isFuelImported: any;
        isMileageImported: any;
    }> = new BehaviorSubject({
        isFuelImported: false,
        isMileageImported: false,
    });

    downloadReportOptions: DownloadReportOptions = {
        name: "SELECT_ALL",
        checked: false,
        sub: [
            {
                name: 'BASIC_INFORMATION',
                key: "basicInformation",
                checked: false,
                isAttachment: false
            },
            {
                name: 'IFTA',
                key: "ifta",
                checked: false,
                isAttachment: false
            },
            {
                name: 'IFTA_REPORT',
                key: 'isTaxReturnReport',
                parent: 'ifta',
                checked: false,
                isAttachment: true
            },
            {
                name: 'NY_HUT',
                key: 'nyHut',
                checked: false,
                isAttachment: false
            },
            {
                name: 'NY_HUT_REPORT',
                key: 'isTaxReturnReport',
                parent: 'nyHut',
                checked: false,
                isAttachment: true
            },
            {
                name: 'KYU',
                key: 'kyu',
                checked: false,
                isAttachment: false
            },
            {
                name: 'KYU_REPORT',
                key: 'isTaxReturnReport',
                parent: 'kyu',
                checked: false,
                isAttachment: true
            },
            {
                name: 'NM_PERMIT',
                key: 'nmPermit',
                checked: false,
                isAttachment: false
            },
            {
                name: 'NM_REPORT',
                key: 'isTaxReturnReport',
                parent: 'nmPermit',
                checked: false,
                isAttachment: true
            },
            {
                name: 'OR_PERMIT',
                key: 'orPermit',
                checked: false,
                isAttachment: false
            },
            {
                name: 'OR_REPORT',
                key: 'isTaxReturnReport',
                parent: 'orPermit',
                checked: false,
                isAttachment: true
            },
        ],
    }

    /**API Calls */

    async getFilingPeriod(
        year: number,
        quarter: number
    ): Promise<ApiResponse<FilingPeriod>> {
        const response = await lastValueFrom(this.http
            .get<ApiResponse<FilingPeriod>>(
                `${API_URL}Filing/FilingPeriod?year=${year}&quarterNumber=${quarter}`
            ));

        return response;
    }

    async getAllFilings(
        options?: GetDataOptions
    ): Promise<ApiResponse<PagingData<FilingPeriodFpList>>> {
        const response = await lastValueFrom(this.http
            .get<ApiResponse<PagingData<FilingPeriodFpList>>>(
                `${API_URL}Filing/FilingPeriod/FpList?pageSize=${
                    options?.pageSize ? options?.pageSize : 10000
                }&pageIndex=${
                    options?.pageIndex ? options?.pageIndex : 0
                }&orderBy=${options?.sort ? options?.sort : ''}&searchString=${
                    options?.searchString ? options?.searchString : ''
                }`
            ));

        return response;
    }

    async addFiling(filingModel: FilingPeriod): Promise<ApiResponseAny> {
        const response = await lastValueFrom(this.http
            .post<ApiResponseAny>(`${API_URL}Filing/FilingPeriod`, filingModel));

        return response;
    }

    async updateFiling(filingModel: FilingPeriod): Promise<ApiResponseAny> {
        const response = await lastValueFrom(this.http
            .put<ApiResponseAny>(`${API_URL}Filing/FilingPeriod`, filingModel));

        return response;
    }

    async importFuelData(
        year: number,
        quarter: number,
        documentId: number
    ): Promise<ApiResponse<FilingPeriod>> {
        const response = await lastValueFrom(this.http
            .put<ApiResponse<FilingPeriod>>(
                `${API_URL}Filing/FilingPeriod/ImportFuelData`,
                {
                    year: year,
                    quarterNumber: quarter,
                    excelDocumentId: documentId,
                }
            ));

        return response;
    }

    async importMileageData(
        year: number,
        quarter: number,
        documentId: number
    ): Promise<ApiResponse<FilingPeriod>> {
        const response = await lastValueFrom(this.http
            .put<ApiResponse<FilingPeriod>>(
                `${API_URL}Filing/FilingPeriod/ImportMileageData`,
                {
                    year: year,
                    quarterNumber: quarter,
                    excelDocumentId: documentId,
                }
            ));

        return response;
    }

    async getIntegrationStatus(
        year: number,
        quarter: number
    ): Promise<ApiResponse<FilingPeriod>> {
        const response = await lastValueFrom(this.http
            .get<ApiResponse<FilingPeriod>>(
                `${API_URL}Filing/FilingPeriod/IntegrationStatus?year=${year}&quarterNumber=${quarter}`
            ));

        return response;
    }

    async getYearAndQuarter(
        year?: number
    ): Promise<ApiResponse<YearAndQuarter[]>> {
        const response = await lastValueFrom(this.http
            .get<ApiResponse<YearAndQuarter[]>>(
                `${API_URL}Filing/FilingPeriod/YeaQuarter/FpList${
                    year ? '?year=' + year : ''
                }`
            ));

        return response;
    }

    async getFilingPermitList(
        year: number,
        quarter: number
    ): Promise<ApiResponse<FilingPermitList[]>> {
        const response = await lastValueFrom(this.http
            .get<ApiResponse<FilingPermitList[]>>(
                `${API_URL}Filing/PermitFiling/FpList?year=${year}&quarterNumber=${quarter}`
            ));

        return response;
    }

    async getPermitDetails(
        year: number,
        quarter: number,
        permitType: PermitTypeEnum | string
    ): Promise<ApiResponse<FilingPermit>> {
        const response = await lastValueFrom(this.http
            .get<ApiResponse<FilingPermit>>(
                `${API_URL}Filing/PermitFiling?year=${year}&quarterNumber=${quarter}&permitType=${permitType}`
            ));

        return response;
    }

    async updatePermitDetails(
        permitId: number,
        permitModel: FilingPermit
    ): Promise<ApiResponse<FilingPermit>> {
        const response = await lastValueFrom(this.http
            .put<ApiResponse<FilingPermit>>(
                `${API_URL}Filing/PermitFiling/${permitId}`,
                permitModel
            ));

        return response;
    }

    async getFilingFuelColumnsAux(): Promise<
        ApiResponse<ColumnsFromApi[]>
    > {
        let response = await lastValueFrom(this.http
            .get<ApiResponse<ColumnsFromApi[]>>(
                `${API_URL}Filing/FilingPeriod/ImportFuelColumn/FpList`
            ));

        return response;
    }

    async importFuelList(
        mappedColoums: MappedColumns
    ): Promise<ApiResponseAny> {
        const response = await lastValueFrom(this.http
            .put<ApiResponseAny>(
                `${API_URL}Filing/FilingPeriod/ImportFuelData`,
                mappedColoums
            ));
        return response;
    }

    async getFilingMileageColumnsAux(): Promise<
        ApiResponse<ColumnsFromApi[]>
    > {
        let response = await lastValueFrom(this.http
            .get<ApiResponse<ColumnsFromApi[]>>(
                `${API_URL}Filing/FilingPeriod/ImportMileageColumn/FpList`
            ));

        return response;
    }

    async importMileageList(
        mappedColoums: MappedColumns
    ): Promise<ApiResponseAny> {
        const response = await lastValueFrom(this.http
            .put<ApiResponseAny>(
                `${API_URL}Filing/FilingPeriod/ImportMileageData`,
                mappedColoums
            ));
        return response;
    }

    async downloadTable(
        year: number,
        quarter: number,
        permitType: PermitTypeEnum
    ): Promise<void> {
        this.spinner.show();
        const response = await lastValueFrom(this.http
            .put(
                `${API_URL}Filing/PermitFiling/Download`,
                {
                    year: year,
                    quarterNumber: quarter,
                    permitType: permitType,
                },
                { responseType: 'blob' }
            ));

        await this.fileService.downloadExportedFile(response, 'table');
        this.spinner.hide();
    }

    async downloadImportedData(year: number, quarter: number): Promise<void> {
        this.spinner.show();
        try {
            const response = await lastValueFrom(this.http
                .put(
                    `${API_URL}Filing/FilingPeriod/Download`,
                    {
                        year: year,
                        quarterNumber: quarter,
                    },
                    { responseType: 'blob' }
                ));

            await this.fileService.downloadExportedFile(response, 'table');
        } catch (error: any) {
        } finally {
            this.spinner.hide();
        }
    }

    /**API Calls End */

    getQuarterText(quarterNumber: number) {
        switch (quarterNumber) {
            case 1:
                return 'Q1 (Jan-Mar)';
            case 2:
                return 'Q2 (Apr-Jun)';
            case 3:
                return 'Q3 (Jul-Sep)';
            case 4:
                return 'Q4 (Oct-Dec)';

            default:
                return '';
        }
    }

    getFilingStatus(status: FilingPermitStatusEnum) {
        switch (status) {
            case FilingPermitStatusEnum.Filed:
                return {
                    class: 'green-tag-filled',
                    label: 'FILED',
                };

            case FilingPermitStatusEnum.NotFiled:
                return {
                    class: 'yellow-tag-filled',
                    label: 'NOT_FILED',
                };

            case FilingPermitStatusEnum.NotFiledDateExpired:
                return {
                    class: 'red-tag-filled',
                    label: 'NOT_FILED',
                };

            default:
                return {
                    icon: '',
                    label: '',
                };
        }
    }

    getPermitTitle(type: PermitTypeEnum) {
        switch (type) {
            case PermitTypeEnum.IFTA:
                return 'IFTA';

            case PermitTypeEnum.KYU:
                return 'KYU';

            case PermitTypeEnum.NM:
                return 'NM_PERMIT';

            case PermitTypeEnum.NY:
                return 'NY_HUT';
            case PermitTypeEnum.OR:
                return 'OR_PERMIT';
        }
    }

    getQuarterSingleText(q: number) {
        switch (Number(q)) {
            case 1:
                return 'Q1';
            case 2:
                return 'Q2';
            case 3:
                return 'Q3';
            case 4:
                return 'Q4';

            default:
                return '';
        }
    }

    getQuarterDeadline(q: number, year: number) {
        let deadLineDate = new Date();
        deadLineDate.setFullYear(Number(year));
        if (String(year).length == 4)
            switch (Number(q)) {
                case 1:
                    deadLineDate.setMonth(3);
                    deadLineDate.setDate(30);
                    return this.dateService.fakeISOString(deadLineDate, true, true);
                case 2:
                    deadLineDate.setMonth(6);
                    deadLineDate.setDate(31);
                    return this.dateService.fakeISOString(deadLineDate, true, true);
                case 3:
                    deadLineDate.setMonth(9);
                    deadLineDate.setDate(31);
                    return this.dateService.fakeISOString(deadLineDate, true, true);
                case 4:
                    deadLineDate.setMonth(0);
                    deadLineDate.setDate(31);
                    deadLineDate.setFullYear(Number(year) + 1);
                    return this.dateService.fakeISOString(deadLineDate, true, true);

                default:
                    return '';
            }
        else return '';
    }
}
